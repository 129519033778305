import(/* webpackMode: "eager" */ "/vercel/path0/components/atoms/Link/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/molecules/BreadCrumbs/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/molecules/CryptoCarousel/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/molecules/CryptoTableWrapper/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/molecules/FAQList/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/molecules/ImageCarousel/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/molecules/MapList/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/molecules/RenderInView/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/molecules/SearchLocation/Map.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/molecules/StepsSlider/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/molecules/TabsComponent/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/molecules/TestimonialsCarousel/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/organisms/AlternateLanguageSetter/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/organisms/Articles/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/organisms/AtmSlider/Slider/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/organisms/AtmSlider/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/organisms/Banner/Partials/BannerLocationInput.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/organisms/BlogPostsWithFilters/partials/Content/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/organisms/CalculatorWithButton/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/organisms/DocumentContent/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/organisms/DocumentTable/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/organisms/Fees/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/organisms/Hero/partials/LocationSelect/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/organisms/HeroMasthead/partials/HeroLinkButton/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/organisms/HostAtmForm/Partials/HostAtmFormInner/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/organisms/PartnersSlider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/organisms/RecentArticles/partials/Articles/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/organisms/StepByStepGuideVideo/partials/YoutubeVideo/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/organisms/Tabs/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/organisms/TwitterList/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PrismicNextImage"] */ "/vercel/path0/node_modules/@prismicio/next/dist/PrismicNextImage.js");
;
import(/* webpackMode: "eager", webpackExports: ["PrismicNextLink"] */ "/vercel/path0/node_modules/@prismicio/next/dist/PrismicNextLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["PrismicPreviewClient"] */ "/vercel/path0/node_modules/@prismicio/next/dist/PrismicPreviewClient.js");
;
import(/* webpackMode: "eager", webpackExports: ["PrismicToolbar"] */ "/vercel/path0/node_modules/@prismicio/react/dist/PrismicToolbar.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/images/ellipse-timer.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/add.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/arrow-right.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/arrow-swap-horizontal.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/banner-background-icon.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/btc.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/carousel-arrow-next.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/carousel-arrow-previous.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/check.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/chevron-down.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/chevron-left.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/chevron-right.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/close.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/confirmed-circle.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/directions.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/document-copy.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/dollar-bill.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/error-circle.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/fees/currency-circle-dollar.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/fees/settings.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/fees/shield-security.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/fees/trend-down.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/fees/trend-up.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/info.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/interac-logo.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/logo-big.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/map-pin.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/medal-star.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/message-question.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/minus.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/order-error.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/socials/facebook.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/socials/instagram.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/socials/twitter.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/spinner.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/terms-of-service-map.svg");
